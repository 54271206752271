import React, { Component } from 'react';

import Background from '../images/landing_background.svg';

class Landing extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.scroll = this.scroll.bind(this)
    }

    scroll(position) {
        if (this.props.navUse) return;

        setTimeout(() => {
            this.props.pages.scrollToAnchor(`#${position}`);
        }, 0);
        this.props.navBarUsage(true, position)

        this.setState({
            burger: false,
            projektiDD: false
        })
        setTimeout(() => {
            this.props.navBarUsage(false, position)
        }, 100);
    }

    render() {

        return (
            <>
                <div id="landing-style">
                    <div className="landing-elements">
                        <h1>Poveži <span>& Razvij</span></h1>
                        <p><span>Intrakon</span> je stičišče za povezovanje poslovnih idej, ki se izidejo v <span>tehnološke</span> in <span>netehnološke</span> inovacije</p>
                        <button onClick={() => this.scroll("kontakt", "white")} onTouchEnd={() => this.scroll("kontakt", "white")}>KONTAKT</button>
                        
                    </div>

                    <img className="background" src={Background} alt="background" />
                </div>
            </>
        )
    }
}

export default Landing;