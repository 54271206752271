import React, { Component } from 'react';

import Pageable from 'pageable'

import Landing from './Landing';
import Onas from './Onas';
import Storitve from './Storitve';
import Kontakt from './Kontakt';
import EuProjekti from './EuProjekti';
import SenzorjiPres from './senzorji/SenzorjiPres';

const pages = ["landing", "onas", "storitve", "kontakt", "eu-projekti", "senzorji"];
let strani;

class HomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        
    }

    componentDidMount() {
        strani = new Pageable("#menu", {
            anchors: pages,
            animation: 700,
            onFinish:
                () => {
                    if (window.location.hash === "#landing" || window.location.hash === "") {
                        this.props.changeBackground("black");
                    } else {
                        this.props.changeBackground("white");
                    }
                    
                },
            pips: false,
            infinite: false,
            swipeThreshold: 40,
            
        });
        this.props.assignStrani(strani)
    }

    render() {
        return (

            <div id="menu">
                <div data-anchor="landing">
                    <Landing
                        navBarUsage={this.props.navBarUsage}
                        navUse={this.props.navUse}
                        pages={strani}
                    />
                </div>

                <div data-anchor="onas">
                    <Onas />
                </div>
                <div data-anchor="storitve">
                    <Storitve />
                </div>
                <div data-anchor="kontakt">
                    <Kontakt />
                </div>
                <div data-anchor="eu-projekti">
                    <EuProjekti />
                </div>
                <div data-anchor="senzorji">
                    <SenzorjiPres />
                </div>
                
            </div>

        )
    }
}

export default HomePage;