import p1 from '../../images/pdfs/Temperaturna tipala v industiji_ELPRO Lepenik_01-2020.pdf'
import p2 from '../../images/pdfs/Optični vlakenski senzorji za merjenje mehanskih veličin.pdf'
import p3 from '../../images/pdfs/Iniciativa za senzorje in možnosti sofinanciranja iz EU skladov.pdf'
import p4 from '../../images/pdfs/Strojni vid, pametni senzorji.pdf'
import p5 from '../../images/pdfs/Keramični senzorji tlaka_od materialov do senzorja.pdf'

import p6 from '../../images/pdfs/Aktualni večji javni razpisi.pdf'
import p7 from '../../images/pdfs/Dewesoft - Senzorski sistemi za monitoring vibracij na konstrukcijah.pdf'
import p8 from '../../images/pdfs/Pretok - Izbira merilne metode.pdf'

import p9 from '../../images/pdfs/Merjenje pomikov in zasukov z magnetnimi dajalniki.pdf'
import p10 from '../../images/pdfs/Primeri meritev pomikov, specifičnih deformacij, torzijskih momentov.pdf'


import plakat1 from '../../images/plakati/senzorji_plakat_1.svg'
import plakat2 from '../../images/plakati/senzorji_plakat_2.png'

const predstavitve = [
    {
        name: "Senzorji 4.0",
        number: 0,
        videos: ["https://www.youtube.com/embed/CU-eNErLa8U"],
        poster: plakat1,
        files: [p1, p2, p3, p4, p5],
        file_names: [
            "Temperaturna tipala v industiji",
            "Optični vlakenski senzorji za merjenje mehanskih veličin",
            "Iniciativa za senzorje in možnosti sofinanciranja iz EU skladov",
            "Strojni vid, pametni senzorji",
            "Keramični senzorji tlaka: Od materialov do senzorja"
        ]
    },
    {
        name: "Senzorski Sistemi",
        number: 1,
        videos: [
            "https://www.youtube.com/embed/f949gpKdCI4",
            "https://www.youtube.com/embed/XIIViaNITIw",
            "https://www.youtube.com/embed/GmTmDM7jHzA",
            "https://www.youtube.com/embed/Bx2RnrfLkQg",
            "https://www.youtube.com/embed/YfQSf2NBGqc",
            "https://www.youtube.com/embed/oUd4WxjoHKY"
        ],
        poster: "",
        files: [p6, p7, p8],
        file_names: [
            "Temperaturna tipala v industiji",
            "Optični vlakenski senzorji za merjenje mehanskih veličin",
            "Iniciativa za senzorje in možnosti sofinanciranja iz EU skladov"
        ]
    },
    {
        name: "Merjenje mehanskih veličin",
        number: 2,
        videos: [],
        poster: plakat2,
        files: [p9, p10],
        file_names: [
            "Merjenje pomikov in zasukov z magnetnimi dajalniki",
            "Primeri meritev pomikov, specifičnih deformacij, torzijskih momentov"
        ]
    }
]

export default predstavitve