import React, { Component } from 'react';

import Ppf1 from '../images/profpic1.jpeg';

class Onas extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    

    render() {

        return (
            <>
                <div id="onas-style">
                    <div className="left">
                        <h1>Kdo smo?</h1>
                        <p>Naše ključne pristojnosti: svetovanje, management organizacij, poslovne strategije, podjetništvo, financiranje in management projektov, inoviranje, obvladovanje tveganj, aplikativne raziskave, razvoj in uvedba sodobnih tehnologij, napredni senzorji</p>
                    </div>
                    <div className="right">
                        <img className="profilepic" src={Ppf1} alt="Profile Pic"/>
                        <h2>Andrej Kokol</h2>
                    </div>

                   
                </div>
            </>
        )
    }
}

export default Onas;