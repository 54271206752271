import React, { Component } from 'react';


/* Images */
import k1 from '../images/kontakt_1.svg';
import k2 from '../images/kontakt_2.svg';

class Kontakt extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        return (
            <>
                <div id="kontakt-style">
                    <div className="content">
                        <div className="left">
                            <p>Kako do nas?</p>
                        </div>
                        <div className="right">
                            <div className="kontakt-item">
                                <div className="icon"><img src={k1} alt="ikona" /></div>
                                <h1>Kontaktna oseba</h1>
                                <ul>
                                    <li>Andrej Kokol</li>
                                    <li>Naslov elektronske pošte: <span>kokolandrej2@gmail.com</span></li>
                                    <li>Telefonska številka: <span>+386 31 637 605</span></li>
                                    <li>Naslov: <span>Pot na Novec 12, 2312 Orehova vas</span></li>
                                </ul>
                            </div>

                            <div className="kontakt-item">
                                <div className="icon"><img src={k2} alt="ikona" /></div>
                                <h1>Podatki o podjetju</h1>
                                <ul>
                                    <li>Banka: <span>NKBM d.d.</span></li>
                                    <li>Številka transakcijskega računa: <span>IBAN SI56 0400 1004 9617 914</span></li>
                                    <li>Davčna številka: <span>SI 14588129</span></li>
                                    <li>Matična številka: <span>8074038000</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        )
    }
}

export default Kontakt;