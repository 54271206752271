import React, { Component } from 'react';

import LogoWhite from '../images/logo_white.svg';
import LogoBlack from '../images/logo_black.svg';
import { FiChevronDown } from 'react-icons/fi';
import { FiMenu } from 'react-icons/fi';


class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projektiDD: false,
            burger: false
        }
        this.scroll = this.scroll.bind(this)
        this.changeDD = this.changeDD.bind(this)
        this.handleBurger = this.handleBurger.bind(this)
    }

    handleBurger() {
        this.setState({ burger: !this.state.burger })
    }

    changeDD() {
        this.setState({ projektiDD: !this.state.projektiDD })
    }

    scroll(position) {
        if (window.location.pathname !== "/") {
            window.location.replace("/#" + position);
            return;
        }
        if (this.props.navUse) return;

        setTimeout(() => {
            this.props.pages.scrollToAnchor(`#${position}`);
        }, 0);
        this.props.navBarUsage(true, position)

        this.setState({
            burger: false,
            projektiDD: false
        })
        setTimeout(() => {
            this.props.navBarUsage(false, position)
        }, 100);
    }

    render() {

        let theme = "link " + this.props.background;
        let themeMobile = this.props.background === "white" ? "link black" : "link white";
        let logo = this.props.background === "white" ? LogoWhite : LogoBlack;
        let projekti =
            <div className="btns">
                <button className={theme} onClick={() => this.scroll("eu-projekti", "white")}>
                    EU PROJEKTI
                </button>
                <button className={theme} onClick={() => this.scroll("senzorji", "white")}>
                    SENZORJI
                </button>
            </div>
        let projektiMobile =
            <div className="btns">
                <button className={themeMobile} onClick={() => this.scroll("eu-projekti", "white")}>
                    EU PROJEKTI
                </button>
                <button className={themeMobile} onClick={() => this.scroll("senzorji", "white")}>
                    SENZORJI
                </button>
            </div>
        let arrowClassName;
        let arrowClassNameMobile;
        if (this.state.projektiDD) {
            if (this.props.background === "black") {
                arrowClassName = "arrow up black"
                arrowClassNameMobile = "arrow up white"
            } else {
                arrowClassName = "arrow up white"
                arrowClassNameMobile = "arrow up black"
            }
        } else {
            if (this.props.background === "black") {
                arrowClassName = "arrow black"
                arrowClassNameMobile = "arrow white"
            } else {
                arrowClassName = "arrow white"
                arrowClassNameMobile = "arrow black"
            }
        }
        let mobileBackground = this.props.background === "white" ? "nav-mobile nav-mobile-bg-w" : "nav-mobile nav-mobile-bg-b";
        let burgerIcon = "burger " + this.props.background;
        let mobileNav =
            <div className={mobileBackground}>
                <button className={themeMobile} onClick={() => this.scroll("onas", "white")}>
                    O NAS
                </button>

                <button className={themeMobile} onClick={() => this.scroll("storitve", "white")}>
                    STORITVE
                </button>

                <button className={themeMobile} onClick={() => this.scroll("kontakt", "white")}>
                    KONTAKT
                </button>

                <div className="dropdown">
                    <button className={themeMobile} onClick={this.changeDD}>
                        PROJEKTI <FiChevronDown className={arrowClassNameMobile} />
                    </button>
                    {this.state.projektiDD ? projektiMobile : ""}
                </div>
            </div>



        return (
            <>
                <div id="navbar">
                    <div className="left-btns">
                        <button className={theme} onClick={() => this.scroll("onas", "white")}>
                            O NAS
                        </button>
                        <button className={theme} onClick={() => this.scroll("storitve", "white")}>
                            STORITVE
                        </button>
                    </div>


                    <button className="center-btn" onClick={() => this.scroll("landing", "black")}><img className="logo" src={logo} alt="navlogo" /></button>

                    <div className="right-btns">
                        <button className={theme} onClick={() => this.scroll("kontakt", "white")}>
                            KONTAKT
                        </button>

                        <div className="dropdown">
                            <button className={theme} onClick={this.changeDD}>
                                PROJEKTI <FiChevronDown className={arrowClassName} />
                            </button>
                            {this.state.projektiDD ? projekti : ""}
                        </div>
                    </div>



                </div>

                <div id="mobile-nav">
                    <button onClick={() => this.scroll("landing", "black")}><img className="logo" src={logo} alt="navlogo" /></button>
                    <button onClick={this.handleBurger}><FiMenu className={burgerIcon} /></button>
                    {this.state.burger ? mobileNav : ""}

                </div>

            </>
        )
    }
}

export default Navbar;