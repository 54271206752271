/* --- NPM --- */
import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";

/* Components */
import Navbar from './components/Navbar';

/* Pages */
import HomePage from './pages/HomePage';
import Senzorji from './pages/senzorji/Senzorji';



import './sass/Main.scss';



const pages = ["landing", "onas", "storitve", "kontakt", "eu-projekti", "senzorji"];
const bg = ["black", "white", "white", "white", "white", "white"];



class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      background: "black",
      navUse: false,
      strani: null
    }

    this.navBarUsage = this.navBarUsage.bind(this);
    this.assignStrani = this.assignStrani.bind(this);
    this.changeBackground = this.changeBackground.bind(this);
  }

  componentDidMount() {
    if (window.location.pathname === "/senzorji") {
      this.setState({
        background: "white"
      })
    }


  }

  changeBackground(color) {
    this.setState({
      background: color
    })
  }


  navBarUsage(usage, position) {
    let i;
    for (let j = 0; j < pages.length; j++) {
      if (pages[j] === position) {
        i = j;
        break;
      }
    }

    this.setState({
      navUse: usage,
      background: bg[i]
    })
  }

  assignStrani(param) {
    this.setState({
      strani: param
    })
  }

  render() {

    return (
      <>
        <Navbar
          background={this.state.background}
          navBarUsage={this.navBarUsage}
          navUse={this.state.navUse}
          anchorScroll={this.anchorScroll}
          pages={this.state.strani}
        />

        <Routes>
          <Route path="/" element={
            <HomePage
              navBarUsage={this.navBarUsage}
              navUse={this.state.navUse}
              changeBackground={this.changeBackground}
              assignStrani={this.assignStrani}
            />
          } />
          <Route path="/senzorji" element={<Senzorji />} />
          <Route path="*" element={<HomePage
              navBarUsage={this.navBarUsage}
              navUse={this.state.navUse}
              changeBackground={this.changeBackground}
              assignStrani={this.assignStrani}
            />} />
        </Routes>

      </>
    )
  }
}

export default App;