import React, { Component } from 'react';

class SenzorjiPres extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.relocate = this.relocate.bind(this)
    }

    relocate() {
        window.location.href = "/senzorji";
    }

    render() {

        return (
            <>
                <div id="senzorji-pres-style">
                    <div className="sen-pres-style">
                        <h1>Iniciativa za senzorje</h1>
                        <button className="razisci" onClick={this.relocate} onTouchEnd={this.relocate}><p>RAZIŠČI</p></button>
                    </div>
                </div>
            </>
        )
    }
}

export default SenzorjiPres;