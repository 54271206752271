import React, { Component } from 'react';

/* Images */
import s1 from '../images/storitve_1.svg';
import s2 from '../images/storitve_2.svg';
import s3 from '../images/storitve_3.svg';
import s4 from '../images/storitve_4.svg';

class Storitve extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {

        return (
            <>
                <div id="storitve-style">
                    <div className="content">
                        <div className="left">
                            <p>Kaj vam nudimo?</p>

                        </div>
                        <div className="right">
                            <div className="storitve-item">
                                <div className="icon"><img src={s1} alt="ikona" /></div>
                                <h1>EU Sredstva</h1>
                                <ul>
                                    <li>Zasnova projektnih idej</li>
                                    <li>Po potrebi povezovanje partnerjev v konzorcij</li>
                                    <li>Izdelava dokumentacije in vlog</li>
                                    <li>Management projektov in poročanje</li>
                                </ul>
                            </div>

                            <div className="storitve-item">
                                <div className="icon"><img src={s2} alt="ikona" /></div>
                                <h1>Obvladovanje Tveganj</h1>
                                <ul>
                                    <li>Razvoj in uvedba metodologije za obvladovanje tveganj pri poslovanju v skladu s standardom ISO 31000</li>
                                    <li>Uvajanje metodologij za spodbujanje ustvarjalnosti zaposlenih</li>
                                </ul>
                            </div>

                            <div className="storitve-item">
                                <div className="icon"><img src={s3} alt="ikona" /></div>
                                <h1>Strateški Management</h1>
                                <ul>
                                    <li>Srednjeročni premislek o prihodnosti poslovanja</li>
                                </ul>
                            </div>

                            <div className="storitve-item">
                                <div className="icon"><img src={s4} alt="ikona" /></div>
                                <h1>Inoviranje</h1>
                                <ul>
                                    <li>Uvajanje metodologij za spodbujanje ustvarjalnosti zaposlenih</li>
                                </ul>
                            </div>

                        </div>
                    </div>


                </div>
            </>
        )
    }
}

export default Storitve;