import React, { Component } from 'react';

import pdf from '../../images/pdfs/pdf.svg'

import { BsChevronLeft, BsChevronRight, BsChevronDown } from 'react-icons/bs'

class Senzorji extends Component {
    constructor(props) {
        super(props)
        this.state = {
            left: 0,
            center: 1,
            right: 2,
            showFiles: false
        }
        this.cycle = this.cycle.bind(this)
        this.cycleTwo = this.cycleTwo.bind(this)
        this.changeShowFiles = this.changeShowFiles.bind(this)
    }

    cycle(smer) {
        const lastElPos = this.props.pres.videos.length - 1
        if (smer === "left") {
            if (this.state.left === 0) {
                this.setState({
                    left: lastElPos,
                    center: this.state.center - 1,
                    right: this.state.right - 1
                })
            } else if (this.state.center === 0) {
                this.setState({
                    left: this.state.left - 1,
                    center: lastElPos,
                    right: this.state.right - 1
                })
            } else if (this.state.right === 0) {
                this.setState({
                    left: this.state.left - 1,
                    center: this.state.center - 1,
                    right: lastElPos
                })
            } else {
                this.setState({
                    left: this.state.left - 1,
                    center: this.state.center - 1,
                    right: this.state.right - 1
                })
            }
        } else if (smer === "right") {
            if (this.state.left === lastElPos) {
                this.setState({
                    left: 0,
                    center: this.state.center + 1,
                    right: this.state.right + 1
                })
            } else if (this.state.center === lastElPos) {
                this.setState({
                    left: this.state.left + 1,
                    center: 0,
                    right: this.state.right + 1
                })
            } else if (this.state.right === lastElPos) {
                this.setState({
                    left: this.state.left + 1,
                    center: this.state.center + 1,
                    right: 0
                })
            } else {
                this.setState({
                    left: this.state.left + 1,
                    center: this.state.center + 1,
                    right: this.state.right + 1
                })
            }
        }
    }

    cycleTwo() {
        let temp = this.state.left
        this.setState({
            left: this.state.center,
            center: temp
        })
    }

    changeShowFiles() {
        this.setState({ showFiles: !this.state.showFiles })
    }

    render() {
        let videos;
        let points = [];
        for (let i = 0; i < this.props.pres.videos.length; i++) {
            points.push(
                <div className="point" />
            )
        }
        if (this.props.pres.videos.length === 0) {
            videos = ""
        } else if (this.props.pres.videos.length === 1) {
            videos =

                <iframe title="Predstavitev 1" className="solo-video" src={this.props.pres.videos[0]} allowFullScreen="1"></iframe>

        } else if (this.props.pres.videos.length === 1) {
            videos =
                <div className="videos">
                    <button className="left-btn" onClick={this.cycleTwo}><BsChevronLeft /></button>

                    <iframe title="Predstavitev 1" className="left" src={this.props.pres.videos[this.state.left]} allowFullScreen="1"></iframe>
                    <iframe title="Predstavitev 2" className="center" src={this.props.pres.videos[this.state.center]} allowFullScreen="1"></iframe>


                    <div className="points">

                    </div>
                </div>

        } else {
            videos =
                <div className="videos">
                    <button className="right-btn" onClick={() => this.cycle("right")}><BsChevronRight /></button>

                    <iframe title="Predstavitev 1" className="left" src={this.props.pres.videos[this.state.left]} allowFullScreen="1"></iframe>
                    <iframe title="Predstavitev 2" className="center" src={this.props.pres.videos[this.state.center]} allowFullScreen="1"></iframe>
                    <iframe title="Predstavitev 3" className="right" src={this.props.pres.videos[this.state.right]} allowFullScreen="1"></iframe>

                    <button className="left-btn" onClick={() => this.cycle("left")}><BsChevronLeft /></button>
                </div>
        }
        const plakat = this.props.pres.poster === "" ? "" : <img className={this.props.pres.videos.length === 0 ? "solo-poster" : ""} src={this.props.pres.poster} alt="Poster" />



        let files = [];
        for (let i = 0; i < this.props.pres.files.length; i++) {
            files.push(
                <div className="file">
                    <img src={pdf} alt="pdf" /><div className="wrapper"><a href={this.props.pres.files[i]} download>{this.props.pres.file_names[i]}</a></div>
                </div>
            )
        }

        let ef_dd = !this.state.showFiles ? "btn-up" : "btn-down"

        return (
            <>
                <div id="predstavitev">
                    <button className="back" onClick={this.props.goBack}><BsChevronLeft /><p>Nazaj na predstavitve</p></button>
                    <div className="pres-num">Predstavitev {this.props.pres.number + 1}</div>
                    <div className="pres-name">{this.props.pres.name}</div>
                    <div className="extra-files">
                        <button onClick={this.changeShowFiles} className={ef_dd}>Predstavitve <BsChevronDown /></button>
                        <div className={this.state.showFiles ? "files" : "files no-pad-down"}>{this.state.showFiles ? files : ""}</div>

                    </div>
                    <div className="content">
                        {videos}
                        {plakat}
                    </div>
                </div>
            </>
        )
    }
}

export default Senzorji;