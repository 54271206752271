import React, { Component } from 'react';

import Predstavitev from './Predstavitev'

import predstavitveArray from './Predstavitve'

class Senzorji extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: "none"
        }
        this.pickPres = this.pickPres.bind(this)
        this.goBack = this.goBack.bind(this)
    }


    pickPres(index) {
        this.setState({selected: predstavitveArray[index].number})
    }

    goBack() {
        this.setState({selected: "none"})
    }
    render() {
        
        const predPicker = predstavitveArray.map(p => 
            <div className="pres-wrap" onClick={() => this.pickPres(p.number)} key={p.number}>
                <div className="krog"><p>{p.number + 1}</p></div>
                <div className="title">{p.name}</div>
            </div>
        )
        
        
        const rendered = this.state.selected === "none" ? 
        <div className="content">
            <h1>Iniciativa za senzorje</h1>
            <h2>Predstavitve</h2>
            <div className="picker">{predPicker}</div>
        </div> :
        <Predstavitev goBack={this.goBack} pres={predstavitveArray[this.state.selected]}/>
        
        return (
            <>
                <div id="senzorji-style">
                    {rendered}
                </div>
            </>
        )
    }
}

export default Senzorji;