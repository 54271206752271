import React, { Component } from 'react';

import SPS_png from '../images/sps_logo.png';
import RS_png from '../images/rs_logo.png';
import EU_png from '../images/eu_logo.png';

class EUProjekti extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        return (
            <>
                <div id="eu-projekti-style">
                    <div className="content">
                        <div className="left">
                            <h1>EU Projekti</h1>
                            <h2>Vavčer za prototipiranje</h2>
                            <ul>
                                <li>V okviru javnega poziva Vavčer za prototipiranje je podjetje INTRAKON d.o.o. izpeljalo aktivnost razvoj in izdelava prototipa sestavnega dela livarskega orodja na osnovi vakuumskega spajkanja. Naložbo vavčer za prototipiranje sofinancirata Republika Slovenija in Evropska unija iz Evropskega sklada za regionalni razvoj.</li>
                                <li>Spletna stran evropske kohezijske politike v Sloveniji: <a href="https://www.eu-skladi.si" target="_blank" rel="noreferrer">www.eu-skladi.si</a></li>
                            </ul>
                        </div>
                        <div className="right">
                            <img src={SPS_png} alt="SPS"/>
                            <img src={RS_png} alt="RS"/>
                            <img src={EU_png} alt="EU"/>
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default EUProjekti;